import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import ErrorPage from './components/pages/ErrorPage';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error) => {
      if (
        error.message.includes('401') &&
        window.location.pathname !== '/' && // from homepage do not redirect to login
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/forgot-password' &&
        window.location.pathname !== '/terms-of-use' &&
        window.location.pathname !== '/privacy-policy' &&
        !window.location.pathname.startsWith('/verify/') &&
        !window.location.pathname.startsWith('/reset-password/')
      ) {
        window.location.href = '/login';
      }
    },
  }),
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <App />
        </ErrorBoundary>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
